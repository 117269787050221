<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="offset" v-if="showBar"></div>
      <div class="dashboard__container--header eventsHeader" style="padding:0;">

        <h1 style="padding-bottom:2rem;" v-if="!isApplication && !isNewGroups && !isGroupShifts && !isGroup">My Jobs</h1>
        <h1 style="padding-bottom:1rem;" v-if="isNewGroups && !isGroupShifts && !isGroup">Career Search</h1>
        <h1 style="padding-bottom:2rem;" v-if="isGroupShifts">Upcoming Shifts</h1>
        <!-- <h1 v-if="isGroup">Apply for a Job with Jump</h1> -->
        <div v-if="isGroupShifts || isGroup" class="text-right" style="width:100%;">
            <button class="btn btn__outlined btn__small mb-3 mt-3" @click="goBack">Go Back<i class="fas fa-arrow-left fa-2x ml-3"></i></button>
        </div>
      </div>

      

     <!--  <ProfileDOB :userProfile="userProfile" v-if="userProfile && !userProfile.is18" />

      <ProfileEmail :userProfile="userProfile" v-if="currentUser && !currentUser.emailVerified" />

      <ProfilePhoneVerification v-if="userProfile && userProfile.phoneVerified != 'approved'" /> -->
      
      <router-view :key="$route.params.id" />
<!--       <div class="dashboard__container--body pt-3 eventCardContainer">
        <div v-for="(item, index) in groups" :key="item.id" @click="onItemClick(item)">
          
            {{item.title}}
        </div>
      </div> -->
    </div>
  </div>
</template>


<style type="text/css" scoped>
  .offset {
    padding-top: 3.6rem;
  }
</style>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import ProfilePhoneVerification from '@/components/Profile/ProfilePhoneVerification.vue'
import ProfileEmail from '@/components/Profile/ProfileEmail.vue'
import ProfileDOB from '@/components/Profile/ProfileDOB.vue'

export default {
  name: 'newGroupsHome',
  data: () => ({
    performingRequest: false,
  }),
  components: {
    ProfilePhoneVerification,
    ProfileEmail,
    ProfileDOB
  },
  created() {
    this.$store.dispatch("getGroups")
  },
  computed: {
    isApproved() {
      return ((this.userProfile && this.userProfile.phoneVerified == 'approved'))
    },
    ...mapState(['groups', 'userProfile', 'currentUser']),
    isMyGroups() {
      return this.$route.name == 'myGroups';
    },
    isNewGroups() {
      return this.$route.name == 'findGroups';
    },
    isApplication() {
      return this.$route.name == 'groupApplication';
    },
    // isShifts() {
    //   return this.$route.name == 'availableShifts';
    // },
    // isMyShifts() {
    //   return this.$route.name == 'myShifts';
    // },
    isGroupShifts() {
      return this.$route.name == 'groupShifts';
    },
    isGroup() {
      return this.$route.name == 'group';
    },
    filteredGroups() {
      if (!this.searchText) return this.items
      return this.items.filter(item => {
        return (item.title.toLowerCase().includes(this.search.toLowerCase()) || item.contact.toLowerCase().includes(this.search.toLowerCase()));
      })
    },
    showBar() {
      if ((!this.userProfile || !this.userProfile.id) && (this.$route.name == 'home' || this.$route.name == 'findGroups')) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
     goBack() {
      router.go(-1)
    },
  },
  // beforeDestroy () {
  //   this.$store.dispatch('clearGroupState')
  // }
  metaInfo: () => ({
    title: 'Job Search',
    // titleTemplate: '%s | Job Search',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: 'Jumpstart your gig work career. Search hundreds of food service, facilities service, and hospitality jobs online. Get hired and get paid all in one platform.'
      }
    ],
  })
}
</script>